import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import track from "../../components/track"
import heroAbout from "../../images/hero-about.png"
import heroAboutLarge from "../../images/hero-about-large.png"
import heroAboutMobile from "../../images/hero-about-mobile.png"
import diagramOne from "../../images/diagram-80-2@2x.png"
import diagramTwo from "../../images/diagram-71-2@2x.png"

const IsMovantikRight = () => {

  const pageTitle = 'Burden of OIC';
  const pageDescription = 'Find out how MOVANTIK is specifically designed to treat opioid-induced constipation with its mechanism of action (MOA).';
  const pageName = "Burden of OIC";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="patient" pageSection="patient-burden-of-oic" pageClass="burden-of-oic" pageName={pageName}>
      <div className="page-title">
        <div className="container">Burden of OIC</div>
      </div>
      <div className="hero">
        <img src={heroAboutLarge} className="hero-img large" alt="" />
        <img src={heroAbout} className="hero-img medium" alt="" />
        <img src={heroAboutMobile} className="hero-img mobile" alt="" />
        <div className="container">
          <div className="headlines">
            <div className="eyebrow">Suffering from <span className="nowrap">opioid-induced constipation?</span></div>
            <h1>You’re not alone.<br />OIC is prevalent—yet<br />undertreated.</h1>
          </div>
        </div>
      </div>
      <div className="headline-divider large"></div>
      <div className="container">
        <h3>Don’t bear the burden of OIC alone – relief can begin with a conversation<sup>1,</sup>*</h3>
        <div className="columns two">
          <div className="column left-column">
            <img src={diagramOne} className="chart" alt="chart" />
          </div>
          <div className="column right-column">
            <img src={diagramTwo} className="chart" alt="chart" />
          </div>
        </div>
        <div className="end-matter">
          <div className="footnotes">
          <div className="footnote asterisk">*In an analysis of a multinational longitudinal study of 493 patients with chronic non-cancer pain and clinician-identified, patient-confirmed OIC.<sup>1</sup></div>
            <div className="footnote"><sup>&dagger;</sup>Constipation treatments included OTC laxatives (stool softeners, osmotics, stimulants, salines, and rectal options), prescription laxatives, and behavioral therapies (fiber supplements, increased fluids and exercise, and dietary changes). 29% of patients in the study reported “much benefit” from constipation treatments.<sup>1</sup></div>
            
          </div>
        <div className="center">
          <Link to="/about/" id="patient-about2-btn-explore" className="button tall" onClick={() => track("patient Is Movantik Right for You page", "button click", "DTC About Movantik-MOVANTIK to help Button")}>
            <div className="btn-text">OTC treatment is usually <span className="bold">not enough</span><sup>6</sup></div>
            <div className="btn-arrow"></div>
          </Link>
        </div>
        
          <div className="references">
            {/* <span className="bold">References: 1.</span> MOVANTIK<sup>®</sup> (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023. <span className="bold">2.</span> Data on file. Prescription data. Valinor Pharma, LLC; 2023. <span className="bold">3.</span> Coyne KS, LoCasale RJ, Datto CJ, Sexton CC, Yeomans K, Tack J. Opioid-induced constipation in patients with chronic noncancer pain in the USA, Canada, Germany, and the UK: descriptive analysis of baseline patient-reported outcomes and retrospective chart review. <span className="italic">Clinicoecon Outcomes Res</span>. <span className="nowrap">2014;6:269-281</span>. <span className="bold">4.</span> Bell TJ, Panchal SJ, Miaskowski C, et al. The prevalence, severity, and impact of opioid-induced bowel dysfunction: results of a US and European Patient Survey (PROBE 1). <span class="italic">Pain Med</span>. 2009;10:35-42. <span className="bold">5.</span> Abramowitz L, Beziaud N, Labreze L. Prevalence and impact of constipation and bowel dysfunction induced by strong opioids: a cross-sectional survey of 520 patients with cancer pain: DYONISOS study. <span className="italic">J Med Econ</span>. <span className="nowrap">2013;16(2):1423-1433</span>. <span className="bold">6.</span> Kalso E, Edwards JE, Moore RA, et al. Opioids in chronic non-cancer pain: systematic review of efficacy and safety. <span className="italic">Pain</span>. 2004;112:372-380. */}
            <span className="bold">References: 1.</span> Coyne KS, LoCasale RJ, Datto CJ, Sexton CC, Yeomans K, Tack J. Opioid-induced constipation in patients with chronic noncancer pain in the USA, Canada, Germany, and the UK: descriptive analysis of baseline patient-reported outcomes and retrospective chart review. <span className="italic">Clinicoecon Outcomes Res</span>. 2014;6:269-281. <span className="bold">2.</span> Bell TJ, Panchal SJ, Miaskowski C, et al. The prevalence, severity, and impact of opioid-induced bowel dysfunction: results of a US and European Patient Survey (PROBE 1). <span className="italic">Pain Med</span>. 2009;10:35-42. <span className="bold">3.</span> Abramowitz L, Beziaud N, Labreze L. Prevalence and impact of constipation and bowel dysfunction induced by strong opioids: a cross-sectional survey of 520 patients with cancer pain: DYONISOS study. <span className="italic">J Med Econ</span>. 2013;16(2):1423-1433. <span className="bold">4.</span> Kalso E, Edwards JE, Moore RA, et al. Opioids in chronic non-cancer pain: systematic review of efficacy and safety. <span className="italic">Pain</span>. 2004;112:372-380. <span className="bold">5.</span> Crockett SD, Greer KB, Heidelbaugh JJ, et al. American Gastroenterological Association Institute Clinical Guidelines Committee. <span className="italic">Gastroenterology</span>. 2019;156:218-226. <span className="bold">6.</span> Brock C, Olesen SS, Olesen AE, Frøkjaer JB, Andresen T, Drewes AM. Opioid-induced bowel dysfunction: pathophysiology and management. <span className="italic">Drugs</span>. 2012;72(14):1847-1865.
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IsMovantikRight
